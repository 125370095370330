import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import { IconButton, LoadingIndicator } from "../../../../components";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  useAnalyticsTopicCountsQuery,
  useAnalyticsTopicOccurrencesCsvLazyQuery,
  useAnalyticsTopicOccurrencesQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import {
  ReportContainer,
  ResultControls,
  ResultHeader,
} from "../AnalyticsReport";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import LabeledChartSelect from "../LabeledChartSelect";
import ToggleFiltersButton from "../ToggleFiltersButton";
import ToggleFiltersPanel from "../ToggleFiltersPanel";
import { CommonQueryVariables } from "../types";
import AnalyticsTopicTrendsChart from "./AnalyticsTopicTrendsChart";
import AnalyticsTopicTrendsTable from "./AnalyticsTopicTrendsTable";
import EditCustomTopicControls from "./EditCustomTopicControls";
import useTopicTrendsConfig, { formatTopicName } from "./useTopicTrendsConfig";

const AnalyticsTopicTrends: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const [showFilters, setShowFilters] = useState(true);
  const [isEditingTopic, setIsEditingTopic] = useBoolean(false);
  const [filterHeights, setFilterHeights] = useState<{ [key: string]: number }>(
    {
      defaultHeight: 50,
    }
  );
  const queryConfig = useTopicTrendsConfig();
  const currentUser = useCurrentUser();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = {
    dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
    ...queryConfig.filters,
  };

  const { data: tableResult, loading: tableLoading } =
    useAnalyticsTopicOccurrencesQuery({
      variables: {
        ...queryVariables,
        topic: queryConfig.topic.value,
        speakerType: queryConfig.speakerType.value,
      },
    });
  const tableData = tableResult?.singleTopicOccurrences?.data || [];

  const { data: chartResult, loading: chartLoading } =
    useAnalyticsTopicCountsQuery({
      variables: {
        ...queryVariables,
        topic: queryConfig.topic.value,
      },
    });
  const chartData = chartResult?.singleTopicCounts;
  const sampleSize = chartData?.data.reduce((acc: number, d) => {
    const total = d.totalCalls ?? 0;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return acc + total;
  }, 0);

  const [fetchCsv, { loading: csvLoading }] =
    useAnalyticsTopicOccurrencesCsvLazyQuery({
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data?.singleTopicOccurrencesCsv?.url) {
          const url = data?.singleTopicOccurrencesCsv?.url;
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  const canEditTopic = false;
  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Topic Trends
          </Text>
        </Flex>
        <Flex ml="auto">
          <ToggleFiltersButton
            open={showFilters}
            toggleFilters={() => {
              sendGAEvent(
                `filters_${!showFilters ? "open" : "closed"}`,
                "analytics"
              );
              setShowFilters((state) => !state);
            }}
          />
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex my="4">
        <ToggleFiltersPanel
          showFilters={showFilters}
          filterHeights={filterHeights}
          flex="1"
          mt="4"
          mb="0"
        >
          <AnalyticsFilters
            queryConfig={queryConfig}
            queryVariables={queryVariables}
            filterHeights={filterHeights}
            setFilterHeights={setFilterHeights}
          />
        </ToggleFiltersPanel>
      </Flex>
      <Flex mt="-2" mb="5">
        <Flex flex="1" alignItems="flex-end">
          <LabeledChartSelect
            data-testid="analytics-topic-trends--topic-menu"
            flexDir="column"
            alignItems="flex-start"
            label="Topic"
            singleSelect={queryConfig.topic}
            customSelectStyles={{
              container: (provided: any) => ({ ...provided, width: "255px" }),
            }}
          />
          {canEditTopic && (
            <Tooltip
              label={
                canEditTopic ? "Edit topic" : "Basic topics cannot be edited"
              }
              placement="bottom-start"
            >
              <IconButton
                color="gray.900"
                aria-label="Edit topic"
                variant="icon"
                icon={<HiOutlinePencil strokeWidth="1.5" size="20px" />}
                disabled={!canEditTopic}
                ml={2}
                onClick={setIsEditingTopic.on}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      {isEditingTopic && (
        <HStack spacing={4} alignItems="center">
          <EditCustomTopicControls topic={queryConfig.topic.value} />
        </HStack>
      )}
      <ReportContainer>
        <ResultHeader
          headerText="Topic Trends"
          captionText={`The percentage of interviews in which the topic was discussed. ${
            sampleSize ? `Sample: ${sampleSize} interviews.` : ""
          }`}
        >
          <Text
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
            whiteSpace="nowrap"
          >
            {queryConfig.dateRange.displayValue}
          </Text>
        </ResultHeader>
        <Flex flexDir="row" justifyContent="space-between" alignItems="center">
          {chartLoading && <LoadingIndicator />}
          {!!chartData?.data.length && !chartLoading && (
            <AnalyticsTopicTrendsChart
              data={chartData?.data}
              queryConfig={queryConfig}
              bucketInterval={chartData?.bucketInterval}
              bucketSize={chartData?.bucketSize}
            />
          )}
        </Flex>
      </ReportContainer>
      <ReportContainer mt="6">
        <ResultHeader
          headerText={`Topic Mentions: ${formatTopicName(
            queryConfig.topic.value
          )}`}
        >
          <Text
            color="gray.600"
            fontWeight="400"
            fontSize="sm"
            whiteSpace="nowrap"
          >
            {queryConfig.dateRange.displayValue}
          </Text>
        </ResultHeader>
        <ResultControls>
          <LabeledChartSelect
            data-testid="analytics-topic-trends--speaker-menu"
            flexDir="column"
            alignItems="flex-start"
            label="Include mentions from: "
            singleSelect={queryConfig.speakerType}
            customSelectStyles={{
              container: (provided: any) => ({ ...provided, width: "250px" }),
            }}
          />
          <Tooltip label="Download full CSV results">
            <IconButton
              aria-label="Download full CSV results"
              icon={<Icon as={HiArrowDownTray} boxSize="5" />}
              isLoading={csvLoading || tableLoading}
              variant="ghost"
              onClick={() => {
                sendGAEvent("download_topic_trend_results", "analytics");
                fetchCsv({
                  variables: {
                    ...queryVariables,
                    topic: queryConfig.topic.value,
                    speakerType: queryConfig.speakerType.value,
                  },
                });
              }}
              onFocus={(e) => e.preventDefault()}
              hidden={!tableLoading && !tableData.length}
            />
          </Tooltip>
        </ResultControls>

        <Box mt="3" width="100%">
          {tableLoading && <AnalyticsReportTableSkeleton />}
          {!!tableData.length && !tableLoading && (
            <AnalyticsTopicTrendsTable
              currentTopic={queryConfig.topic.value}
              tableData={tableData}
            />
          )}
        </Box>
      </ReportContainer>
    </>
  );
};

export default AnalyticsTopicTrends;
