import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import {
  PiArrowsInLineVerticalFill,
  PiArrowsOutLineVerticalFill,
} from "react-icons/pi";

import { IconButton, Link } from "../../../../components";
import { primaryButtonGrayProps } from "../../../../plan/components/utils";
import { formatDuration } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import MonospacedText from "../../MonospacedText";
import ColumnHeader from "./ColumnHeader";
import { Note, Topic } from "./types";
import { encodeToAnchor } from "./utils";

/**
 * Derived from the Notes.tsx for "other" AI notes formats on the call page.
 */
const TopicsAndNotes: React.FC<{
  topics: Topic[];
  onClickTimestamp(callId: string, t: number): void;
}> = ({ onClickTimestamp, topics }) => {
  const [expandedIndices, setExpandedIndices] = React.useState<
    Map<string, number[]>
  >(new Map());

  const sendGAEvent = useSendGAEvent();
  const allClosed = expandedIndices.size === 0;
  const toggleAll = (): void => {
    if (allClosed) {
      sendGAEvent("candidate_summary_expand_all_subtopics", "candidate");
      const newMap = new Map<string, number[]>();
      topics.forEach((topic) => {
        const allIndices = topic.subTopics.reduce<number[]>((arr, _, idx) => {
          return [...arr, idx];
        }, []);
        newMap.set(topic.name, allIndices);
      });
      setExpandedIndices(newMap);
    } else {
      sendGAEvent("candidate_summary_collapse_all_subtopics", "candidate");
      setExpandedIndices(new Map());
    }
  };

  const handleExpandChange = (topicName: string, indices: number[]): void => {
    setExpandedIndices((prevMap) => {
      const newMap = new Map(prevMap);
      if (indices.length === 0 && newMap.has(topicName)) {
        sendGAEvent("candidate_summary_collapse_subtopic", "candidate");
        newMap.delete(topicName);
      } else {
        sendGAEvent("candidate_summary_expand_subtopic", "candidate");
        newMap.set(topicName, indices);
      }
      return newMap;
    });
  };

  const expandedContainsSection = (topicName: string, idx: number): boolean => {
    const section = expandedIndices.get(topicName);
    if (!section) return false;
    return section.includes(idx);
  };

  let citationCount = 0;
  const citationMap = useMemo(() => {
    const cMap: { [key: string]: number } = {};
    topics.forEach((topic) => {
      topic.subTopics?.forEach((subTopic) => {
        subTopic.notes?.forEach((note) => {
          note.citations.forEach((citation) => {
            citationCount += 1;
            cMap[citation.id] = citationCount;
          });
        });
      });
    });
    return cMap;
  }, [topics]);

  const renderedHeaders = topics.map((topic) => {
    return (
      <Box mb={4} fontSize="sm" key={topic.name} pb="12px">
        <Flex
          pb="6px"
          flexDir="row"
          alignItems="center"
          position="relative"
          role="group"
          gap="2"
        >
          <Flex
            fontWeight="600"
            fontSize="sm"
            bg="gray.50"
            px={4}
            py="10px"
            id={encodeToAnchor("topic", topic.name)}
            width="100%"
            borderRadius="lg"
            mb={2}
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            color="gray.800"
          >
            <Box>{topic.name}</Box>
            <Box>
              {topic.subTopics.reduce<number>((count, subTopic) => {
                return (
                  count +
                  subTopic.notes.reduce<number>((noteCount, note) => {
                    return noteCount + note.citations.length;
                  }, 0)
                );
              }, 0)}
            </Box>
          </Flex>
        </Flex>
        <Accordion
          allowMultiple
          color="gray.700"
          index={expandedIndices.get(topic.name) || []}
          onChange={(indices: number[]) => {
            handleExpandChange(topic.name, indices);
          }}
        >
          {topic.subTopics?.length === 0 || !topic.subTopics ? (
            <EmptyStateNote errorText="No results found for this topic." />
          ) : undefined}
          {topic.subTopics
            .filter(
              (subTopic) =>
                subTopic.name !== "Other" ||
                (subTopic.notes.length > 0 && subTopic.notes[0].text !== "")
            )
            .map((subTopic, idx) => {
              if (subTopic.notes.length === 0) {
                return "";
              }
              return (
                <AccordionItem
                  key={`${topic.name}-${subTopic.name}`}
                  border="0px"
                >
                  <AccordionButton
                    _hover={{ bg: "transparent", color: "blue.600" }}
                    borderRadius="base"
                    height="10"
                    pl={0}
                  >
                    <AccordionIcon
                      transform={
                        expandedContainsSection(topic.name, idx)
                          ? "rotate(0deg)"
                          : "rotate(-90deg)"
                      }
                      color="blue.600"
                      mr={1}
                    />
                    <Flex
                      dir="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Text
                          fontSize="sm"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          marginRight="auto"
                          color="gray.800"
                          fontWeight={400}
                          _hover={{
                            color: "blue.600",
                          }}
                        >
                          {subTopic.name}
                        </Text>
                      </Box>
                      <Box>
                        {subTopic.notes.reduce<number>((noteCount, note) => {
                          return noteCount + note.citations.length;
                        }, 0)}
                      </Box>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel py="1">
                    {subTopic.notes[0].text === "" ? (
                      <EmptyStateNote errorText="No results found for this subtopic." />
                    ) : undefined}
                    <UnorderedList ml={6}>
                      {subTopic.notes
                        .filter((n) => n.text.length > 0)
                        .map((note) => {
                          return (
                            <SummaryNote
                              key={note.id}
                              note={note}
                              citationMap={citationMap}
                              onClickTimestamp={onClickTimestamp}
                            />
                          );
                        })}
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </Box>
    );
  });
  return (
    <Box fontSize="sm" overflow="auto" px={6}>
      <Flex flexDir="row" mb={2} alignItems="center" pt={1}>
        <Flex dir="row">
          <ColumnHeader mb={2}>Topics</ColumnHeader>
          <Tooltip
            bg="white"
            p="2"
            ml={2}
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            color="gray.800"
            boxShadow="none"
            fontSize="sm"
            shouldWrapChildren
            fontWeight="normal"
            placement="top"
            mb={2}
            label={
              <>
                This summary was generated by AI across all interviews. Topics
                are derived from the job description, when available.
              </>
            }
          >
            <Icon
              boxSize={4}
              color="gray.700"
              as={HiOutlineInformationCircle}
              ml={1}
            />
          </Tooltip>
        </Flex>
        <Tooltip label={allClosed ? "Expand topics" : "Collapse topics"}>
          <IconButton
            ml="auto"
            aria-label="Expand questions"
            icon={
              <Icon
                as={
                  allClosed
                    ? PiArrowsOutLineVerticalFill
                    : PiArrowsInLineVerticalFill
                }
                boxSize={5}
              />
            }
            size="sm"
            onClick={toggleAll}
            {...primaryButtonGrayProps}
          />
        </Tooltip>
      </Flex>
      {renderedHeaders}
    </Box>
  );
};

type NoteProps = {
  note: Note;
  citationMap: { [key: string]: number };
  onClickTimestamp(callId: string, t: number): void;
};

const SummaryNote: React.FC<NoteProps> = ({
  note,
  onClickTimestamp,
  citationMap,
}) => {
  return (
    <ListItem
      key={note.id}
      _hover={{
        bg: "blue.50",
      }}
      cursor="pointer"
    >
      <Text as="span" pr={1} color="gray.800">
        {note.text}
      </Text>
      <Text as="span" verticalAlign="top" fontSize="xs">
        {note.citations.map((citation, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={citation.id}>
            {idx > 0 && " "}
            <Tooltip label={formatDuration(Math.round(citation.time))}>
              <Link
                fontWeight="normal"
                href={`/interview/${citation.callId}?t=${citation.time}`}
                onClick={(e) => {
                  e.preventDefault();
                  onClickTimestamp(citation.callId, citation.time);
                }}
                pt={2}
              >
                <MonospacedText
                  display="inline-block"
                  text={citationMap[citation.id].toString()}
                />
              </Link>
            </Tooltip>
          </React.Fragment>
        ))}
      </Text>
    </ListItem>
  );
};

const EmptyStateNote: React.FC<{ errorText: string }> = ({ errorText }) => {
  return (
    <Box>
      <Flex direction="row" align="baseline">
        <Text as="span">
          <Text as="span" pr={1} color="gray.500">
            {errorText}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default TopicsAndNotes;
