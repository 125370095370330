import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

interface EditCustomTopicControlsProps {
  topic: any;
}

type MatchRuleType = "all" | "any";

interface FormValues {
  name: string;
  keywords: string[];
  matchRule: MatchRuleType;
}

const EditCustomTopicControls: React.FC<EditCustomTopicControlsProps> = ({
  topic,
}) => {
  const defaultValues: FormValues = {
    name: "",
    keywords: [],
    matchRule: "all",
  };

  const {
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const formControlStyle = {
    color: "gray.600",
    fontSize: "sm",
  };

  return (
    <>
      <FormControl isRequired isInvalid={errors.name !== undefined}>
        <FormLabel htmlFor="name" {...formControlStyle}>
          Topic name
        </FormLabel>
        <Input {...register("name")} />
      </FormControl>
      <FormControl isRequired isInvalid={errors.keywords !== undefined}>
        <FormLabel htmlFor="keywords" {...formControlStyle}>
          Keywords
        </FormLabel>
        <Input {...register("keywords")} />
      </FormControl>
      <FormControl isRequired isInvalid={errors.matchRule !== undefined}>
        <FormLabel htmlFor="matchRule" {...formControlStyle}>
          Include rule
        </FormLabel>
        <Input {...register("matchRule")} />
      </FormControl>
    </>
  );
};

export default EditCustomTopicControls;
