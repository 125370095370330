import { useMemo, useState } from "react";

import {
  dateRange90DaysAgo,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../../hooks/useSearchParam";
import { formatDateRange } from "../../../../utils/datetime";
import { snakeCaseToSentenceCase } from "../../../../utils/string";
import {
  AnalyticsDimension,
  AnalyticsTopic,
  AnalyticsTopicSpeakerType,
} from "../../../graphql";
import { MultiSelect, SelectOption, SingleSelect } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import { isDimensionSupportedForOrg } from "../utils";

export type TopicTrendsConfig = {
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: SingleSelect<AnalyticsDimension>;
  positions: MultiSelect;
  interviewers: MultiSelect;
  departments: MultiSelect;
  stages: MultiSelect;
  dateRange: {
    value: SelectDateRangeState;
    displayValue: string;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  topic: SingleSelect<AnalyticsTopic>;
  speakerType: SingleSelect<AnalyticsTopicSpeakerType>;
  filters: {
    positions: string[];
    interviewers: string[];
    departments: string[];
    stages: string[];
  };
  enabledFilters: {
    positions: boolean;
    interviewers: boolean;
    departments: boolean;
    stages: boolean;
  };
};

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useTopicTrendsConfig = (
  defaultDateRange = dateRange90DaysAgo
): TopicTrendsConfig => {
  const [positions, setPositions] = useStringArrayParam("positions");
  const [interviewers, setInterviewers] = useStringArrayParam("interviewers");
  const [interviewerLabels, setInterviewerLabels] = useState<
    string[] | undefined
  >(undefined);
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [stages, setStages] = useStringArrayParam("stages");
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );
  const [topic, setTopic] = useEnumSearchParam<AnalyticsTopic>(
    "topic",
    AnalyticsTopic.Ai
  );
  const [speakerType, setSpeakerType] =
    useEnumSearchParam<AnalyticsTopicSpeakerType>(
      "speaker",
      AnalyticsTopicSpeakerType.All
    );

  const filters = {
    positions: positions || [],
    interviewers: interviewers || [],
    departments: departments || [],
    stages: stages || [],
  };

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  const { atsDataState } = useAnalyticsContext();
  const stageSupported = isDimensionSupportedForOrg(
    AnalyticsDimension.JobStage,
    atsDataState
  );

  return {
    primaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    secondaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    interviewers: {
      values: interviewers,
      setValues: setInterviewers,
      labels: interviewerLabels,
      setLabels: setInterviewerLabels,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    stages: {
      values: stages,
      setValues: setStages,
    },
    dateRange: {
      // Default is defined inline because it dynamically calculates a range
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    topic: {
      value: topic as AnalyticsTopic,
      setValue: setTopic,
      options: topicOptions(),
    },
    speakerType: {
      value: speakerType as AnalyticsTopicSpeakerType,
      setValue: setSpeakerType,
      options: [
        {
          label: "Candidates and Interviewers",
          value: AnalyticsTopicSpeakerType.All,
        },
        {
          label: "Only candidates",
          value: AnalyticsTopicSpeakerType.Candidate,
        },
        {
          label: "Only interviewers",
          value: AnalyticsTopicSpeakerType.Interviewer,
        },
      ],
    },
    filters,
    enabledFilters: {
      positions: true,
      interviewers: true,
      departments: true,
      stages: stageSupported,
    },
  };
};

export const formatTopicName = (topic: AnalyticsTopic): string => {
  if (topic === AnalyticsTopic.Ai || topic === AnalyticsTopic.Dei) {
    return topic.toLocaleUpperCase();
  }
  return snakeCaseToSentenceCase(topic);
};

export const topicOptions = (): SelectOption<AnalyticsTopic>[] => [
  {
    label: formatTopicName(AnalyticsTopic.Ai),
    value: AnalyticsTopic.Ai,
  },
  {
    label: formatTopicName(AnalyticsTopic.CompensationAndBenefits),
    value: AnalyticsTopic.CompensationAndBenefits,
  },
  {
    label: formatTopicName(AnalyticsTopic.Culture),
    value: AnalyticsTopic.Culture,
  },
  {
    label: formatTopicName(AnalyticsTopic.Dei),
    value: AnalyticsTopic.Dei,
  },
  {
    label: formatTopicName(AnalyticsTopic.Economy),
    value: AnalyticsTopic.Economy,
  },
  {
    label: formatTopicName(AnalyticsTopic.LayoffsAndCompanyStability),
    value: AnalyticsTopic.LayoffsAndCompanyStability,
  },
  {
    label: formatTopicName(AnalyticsTopic.Motivations),
    value: AnalyticsTopic.Motivations,
  },
  {
    label: formatTopicName(AnalyticsTopic.WorkLocation),
    value: AnalyticsTopic.WorkLocation,
  },
];

// cspell:disable
export const TOPIC_KEYWORD_MAP: Record<AnalyticsTopic, string[]> = {
  [AnalyticsTopic.Ai]: [
    "ai",
    "ml",
    "artificial intelligence",
    "machine learning",
    "large language model",
    "llm",
    "llms",
    "chatgpt",
    "chat gpt",
    "openai",
    "open ai",
    "anthropic",
    "gpt",
    "claude",
    "large language models",
  ],
  [AnalyticsTopic.CompensationAndBenefits]: [
    "salary",
    "comp",
    "compensation",
    "equity",
    "benefits",
    "shares",
    "bonus",
    "base salary",
    "stock options",
    "equity shares",
    "equity grant",
    "equity grants",
    "stock option",
    "stock grant",
    "common stock",
    "restricted stock",
    "RSU",
    "RSUs",
    "performance bonus",
    "performance bonuses",
    "signing bonus",
    "signing bonuses",
    "sign on bonus",
    "sign on bonuses",
    "annual bonus",
    "annual bonuses",
    "quarterly bonus",
    "quarterly bonuses",
    "health benefits",
    "retirement benefits",
    "401k",
    "health plan",
    "health insurance",
    "dental insurance",
    "parental leave",
    "pension plan",
    "life insurance",
    "wellness benefits",
    "maternity leave",
    "paternity leave",
  ],
  [AnalyticsTopic.Culture]: ["mission", "values", "culture"],
  [AnalyticsTopic.Dei]: [
    "equity",
    "diversity",
    "diverse",
    "inclusivity",
    "inclusive",
    "dei",
  ],
  [AnalyticsTopic.Economy]: [
    "macro economic",
    "economy",
    "macroeconomic",
    "unemployment rate",
    "employment rate",
    "economic growth",
    "recession",
    "inflation",
    "deflation",
    "economic indicators",
    "fiscal policy",
    "fed policy",
    "interest rates",
    "GDP",
    "labor market",
    "stock market",
  ],
  [AnalyticsTopic.LayoffsAndCompanyStability]: [
    "laid off",
    "layoff",
    "layoffs",
    "cash burn",
    "profitability",
    "funding",
    "profitable",
    "profit",
    "runway",
    "cash flow positive",
    "cashflow positive",
  ],
  [AnalyticsTopic.Motivations]: [
    "goals",
    "career growth",
    "career development",
    "long term",
    "next role",
    "looking for",
    "hoping for",
    "in my next",
    "in your next",
    "in my next role",
  ],
  [AnalyticsTopic.WorkLocation]: [
    "in the office",
    "into the office",
    "remote",
    "remotely",
    "to the office",
    "remote work",
    "flexible work",
    "work from home",
    "hybrid",
    "return to office",
    "in office",
  ],
};
// cspell:enable

export default useTopicTrendsConfig;
