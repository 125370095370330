import { Box, Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineVideoCamera,
} from "react-icons/hi2";

import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  ScheduledInterviewListItemFragment,
  useCandidateSummaryCallCountQuery,
} from "../../graphql";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import { ColumnHeaderBorder, ColumnHeading } from "./ColumnHeading";
import InterviewListItemV2 from "./InterviewListItemV2";
import SummaryTabV2 from "./summary/SummaryTabV2";
import SummaryTooltipIcon from "./summary/SummaryTooltipIcon";
import { CandidateCall } from "./types";

type InterviewTabProps = {
  calls: Array<CandidateCall>;
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  currentUserNotesOnly: boolean;
  candidateId: string;
  positionId?: string;
};

const InterviewTabV2: React.FC<InterviewTabProps> = ({
  calls,
  scheduledInterviews,
  currentUserNotesOnly,
  candidateId,
  positionId,
}) => {
  const [interviewsExpandedIfPossible, setInterviewsExpandedIfPossible] =
    useState(true);
  const currentBreakpoint = useCurrentBreakpoint();
  const interviewsExpanded =
    !["lg", "md", "sm", "base"].includes(currentBreakpoint) &&
    interviewsExpandedIfPossible;
  const { windowHeight } = useWindowDimensions();
  const topRef = React.useRef<HTMLDivElement>(null);
  const [, setForceRender] = useState(false);

  const topWidth = topRef.current?.offsetWidth;
  // We can't calculate full layout until we know the width of the top container,
  // and setting the ref doesn't trigger a re-render, so we need to force one.
  useEffect(() => {
    if (topWidth === undefined) {
      setForceRender(true);
    }
  }, []);

  // We manually calculate dimensions so we can size the inner width of the offscreen
  // debrief content to the size that will be available after it is expanded, so it
  // animates smoothly.
  const dimensions = useColumnDimensions(topWidth || 0, interviewsExpanded);
  const isExtension = useIsExtension();

  const { data: callCountData } = useCandidateSummaryCallCountQuery({
    variables: { candidateId, positionId },
  });
  const summaryCallCount = callCountData?.candidateSummaryCallCount?.count || 0;

  if (["base", "sm"].includes(currentBreakpoint) || isExtension) {
    return (
      <Flex flexDir="column" gap={4}>
        <CollapsibleColumnWrapper
          title="Topics &amp; Summary"
          defaultExpanded
          headerTooltip={
            <SummaryTooltipIcon
              iconProps={{ display: "flex", mt: "0", mb: "2px" }}
            />
          }
        >
          <SummaryTabV2
            candidateId={candidateId}
            positionId={positionId}
            notesExpanded={!interviewsExpanded}
            toggleNotes={() =>
              setInterviewsExpandedIfPossible(!interviewsExpandedIfPossible)
            }
            innerExpandedWidth={dimensions.debriefInnerExpandedWidth}
            summaryCallCount={summaryCallCount}
          />
        </CollapsibleColumnWrapper>
        <CollapsibleColumnWrapper title="Recordings" defaultExpanded>
          <RecordingsColumn
            scheduledInterviews={scheduledInterviews}
            calls={calls}
            currentUserNotesOnly={currentUserNotesOnly}
            interviewsExpanded={interviewsExpanded}
            dimensions={dimensions}
          />
        </CollapsibleColumnWrapper>
      </Flex>
    );
  }

  return (
    <Flex
      ref={topRef}
      flexDir="row"
      gap={4}
      height="100%" // TODO: "undefined" when no content
      maxH={parseInt(windowHeight) - 238}
    >
      {topWidth && (
        <>
          <ColumnWrapper
            width={dimensions.recordingsColumnWidth}
            minWidth={dimensions.recordingsColumnMinWidth}
            transition="width 0.3s"
            pb={0}
          >
            <RecordingsColumn
              scheduledInterviews={scheduledInterviews}
              calls={calls}
              currentUserNotesOnly={currentUserNotesOnly}
              interviewsExpanded={interviewsExpanded}
              dimensions={dimensions}
            />
          </ColumnWrapper>

          <ColumnWrapper
            width={dimensions.debriefColumnWidth}
            minWidth={dimensions.debriefColumnMinWidth}
            transition="width 0.3s"
          >
            <SummaryTabV2
              candidateId={candidateId}
              positionId={positionId}
              notesExpanded={!interviewsExpanded}
              toggleNotes={() =>
                setInterviewsExpandedIfPossible(!interviewsExpanded)
              }
              innerExpandedWidth={dimensions.debriefInnerExpandedWidth}
              summaryCallCount={summaryCallCount}
            />
          </ColumnWrapper>
        </>
      )}
    </Flex>
  );
};

const RecordingsColumn: React.FC<{
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  calls: Array<CandidateCall>;
  currentUserNotesOnly: boolean;
  interviewsExpanded: boolean;
  dimensions: ColumnDimensions;
}> = ({
  scheduledInterviews,
  calls,
  currentUserNotesOnly,
  interviewsExpanded,
  dimensions,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();
  return (
    <Flex flexDir="column" flex="1 1 auto" overflow="hidden">
      {!["base", "sm"].includes(currentBreakpoint) && (
        <Box px={6}>
          <ColumnHeading iconType={HiOutlineVideoCamera} title="Recordings" />
          <ColumnHeaderBorder />
        </Box>
      )}
      <Flex
        direction="column"
        overflow="auto"
        minWidth={
          interviewsExpanded
            ? dimensions.recordingsInnerExpandedWidth
            : undefined
        }
        px={6}
      >
        {scheduledInterviews.length > 0 &&
          scheduledInterviews.map((scheduledInterview, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={scheduledInterview.id}
                scheduledInterview={scheduledInterview}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                collapsed={!interviewsExpanded}
              />
            );
          })}
        {calls.length > 0 &&
          calls.map((call, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={call.id}
                call={call}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                collapsed={!interviewsExpanded}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

type ColumnDimensions = {
  recordingsColumnWidth: string;
  recordingsColumnMinWidth: string;
  recordingsInnerExpandedWidth: string;
  debriefColumnWidth: string;
  debriefColumnMinWidth: string;
  debriefInnerExpandedWidth: string;
};

const useColumnDimensions = (
  totalWidth: number,
  expanded: boolean
): ColumnDimensions => {
  const currentBreakpoint = useCurrentBreakpoint();
  const recordingsColumnMinWidth = 257;
  const debriefColumnMinWidth = 420;
  const recordingsInnerExpandedWidth =
    totalWidth -
    debriefColumnMinWidth - // recordings column width
    16 - // gap between columns
    24 - // padding on debrief column
    2; // border on column

  const debriefInnerExpandedWidth =
    totalWidth -
    recordingsColumnMinWidth - // recordings column width
    16 - // gap between columns
    2; // border on column

  const defaultDimensions = {
    recordingsColumnWidth: `${recordingsColumnMinWidth}px`,
    recordingsColumnMinWidth: `${recordingsColumnMinWidth}px`,
    recordingsInnerExpandedWidth: `${recordingsInnerExpandedWidth}px`,
    debriefColumnWidth: "100%",
    debriefColumnMinWidth: `${debriefColumnMinWidth}px`,
    debriefInnerExpandedWidth: `${debriefInnerExpandedWidth}px`,
  };
  if (currentBreakpoint === "md") {
    return {
      ...defaultDimensions,
      recordingsColumnWidth: `${recordingsColumnMinWidth}px`,
      debriefColumnWidth: "100%",
    };
  }

  if (currentBreakpoint === "lg") {
    return {
      ...defaultDimensions,
      recordingsColumnWidth: `${recordingsColumnMinWidth}px`,
      debriefColumnWidth: "100%",
    };
  }

  if (expanded) {
    return {
      ...defaultDimensions,
      recordingsColumnWidth: "100%",
      debriefColumnWidth: `${debriefColumnMinWidth}px`,
    };
  }

  return {
    ...defaultDimensions,
    recordingsColumnWidth: `${recordingsColumnMinWidth}px`,
    debriefColumnWidth: "100%",
  };
};

const ColumnWrapper: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      h="fit-content"
      maxH="100%"
      border="1px"
      borderColor="gray.100"
      borderRadius="2xl"
      py={4}
      overflow="hidden"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

const CollapsibleColumnWrapper: React.FC<
  {
    title: string;
    children: React.ReactNode;
    defaultExpanded?: boolean;
    headerTooltip?: React.ReactElement;
  } & FlexProps
> = ({ title, defaultExpanded, children, headerTooltip, ...flexProps }) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);
  return (
    <Flex
      flexDir="column"
      border="1px"
      borderColor="gray.100"
      borderRadius="lg"
      py={5}
      {...flexProps}
    >
      <Flex flexDir="row" alignItems="center" px={6}>
        <Text fontSize="md" fontWeight="600" color="gray.800">
          {title}
        </Text>
        {headerTooltip}
        <Icon
          boxSize={6}
          color="blue.600"
          marginLeft="auto"
          cursor="pointer"
          as={expanded ? HiOutlineChevronUp : HiOutlineChevronDown}
          onClick={(e) => {
            setExpanded(!expanded);
          }}
          userSelect="none"
        />
      </Flex>
      {expanded && (
        <>
          <ColumnHeaderBorder mt={5} mx={6} />
          {children}
        </>
      )}
    </Flex>
  );
};

export default InterviewTabV2;
